import React, { useState } from "react";
import consultoria from "../../assets/Consultoria.webp";
import arduino from "../../assets/Electronics.webp";
import erp from "../../assets/ERP.webp";
import ewonflexy from "../../assets/EWONFlexy.webp";
import pluto from "../../assets/PLUTO.webp";
import seo from "../../assets/seo.webp";
import "./proyectos.css";

const Proyectos = ({ title }) => {
    var projects = [
        {
            key: "Proyectos IIoT Industria 4.0",
            title: "Proyectos IIoT Industria 4.0 con EWON",
            imgpath: ewonflexy,
            link: "/",
            info1: "Recogemos tus datos en la propia instalación, utilizamos gestión y notificación de alarmas, registro y recuperación de datos usando protocolos Modbus, MQTT, HTTPs... y publicamos tus KPI en tiempo real en la nube."
        },
        {
            key: "Software de reporte y monitorización de datos",
            title: "Software de reporte y monitorización de datos con Pluto Live Report",
            imgpath: pluto,
            link: "/",
            info1: "Solución fácil y totalmente configurable para la monitorización y reporte de datos, mostrando todas las capacidades de conectar tus procesos de smart buildings, industrial plants, machines y servicios IIoT."
        },
        {
            key: "Optimización SEO",
            title: "Optimización web SEO/SEM",
            imgpath: seo,
            link: "/",
            info1: "Mejora SEO de tu WebApp, optimización de velocidad de carga y recursos del portal, optimización SEO/SEM para mejorar tu posicionamiento web."
        },
        {
            key: "Desarrollo, optimización y mantenimiento de WebApps",
            title: "Desarrollo, optimización y mantenimiento de WebApps",
            imgpath: erp,
            link: "/",
            info1: "Desarrollamos tus WebApp y e-commerce a medida de principio a fin, con presupuestos cerrados y sin sorpresas divididos en fases de desarrollo."
        },
        {
            key: "Integraciones con hardware personalizado",
            title: "Integraciones con hardware personalizado",
            imgpath: arduino,
            link: "/",
            info1: "Te sorprenderá dejarte guiar y darle forma a tu proyecto para que sea escalable empezando con un MVP (Mínimo Producto Viable)."
        },
        {
            key: "Consultoría digital",
            title: "Consultoría digital",
            imgpath: consultoria,
            link: "/",
            info1: "Ayudo a tu empresa a reconvertirse digitalmente y escalar tus proyectos y líneas de negocio."
        }
    ];
    let i = 1;

    const [projectes, setProjectes] = useState(projects.slice(0, 6));

    const listProjects = projectes.map((project) => {
        return (
            <div key={project.key} className="proyectos-card">
                <div className="proyectos-card-top">
                    <div className="proyectos-img-wrapper">
                        <img
                            width="997"
                            src={project.imgpath}
                            alt={project.title}
                        />
                    </div>
                    <h3 className="proyectos-title">{project.title}</h3>
                </div>
                <div className="proyectos-card-bottom">
                    <p>{project.info1}</p>
                </div>
            </div>
        );
    });

    const handleClick = () => {
        setProjectes(projectes.concat(projects.slice(6 * i, 6 * (i + 1))));
        i++;
    };

    return (
        <>
            <div className="proyectos section__padding">
                <div className="proyectos-container">
                    <h2 id="proyectos">{title}</h2>
                    <div className="proyectos-container-card">
                        {listProjects}
                    </div>
                </div>

                <div className="load-more">
                    <button onClick={handleClick} disabled>
                        Ver más
                    </button>
                </div>
            </div>
        </>
    );
};

export default Proyectos;
