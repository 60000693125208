import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { RiGithubFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import CreativeCommons from "../../assets/88x31.png";
import "./footer.css";
const Footer = () => {
    return (
        <div className="footer_section__padding">
            <div className="footer-copyright">
                <div className="creative-commons">
                    <a
                        href="https://creativecommons.org/licenses/?lang=es_ES"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img
                            width="88"
                            height="31"
                            src={CreativeCommons}
                            alt="Licencia Creative Commons - Reconocimiento - No Comercial - Sin obra derivada"
                        />
                    </a>
                </div>
                <div>
                    <p>{new Date().getFullYear()} Carlos Paqué Nuñez</p>
                </div>
                <div>
                    <a
                        href="https://www.linkedin.com/in/carlospaque/"
                        rel="noreferrer"
                        target="_blank"
                        className="linkedin"
                    >
                        <BsLinkedin
                            size={25}
                            color="white"
                            className="footer-icon"
                        />
                    </a>
                </div>
                <div>
                    <a
                        href="https://github.com/bartolomeow"
                        rel="noreferrer"
                        target="_blank"
                        className="github"
                    >
                        <RiGithubFill
                            size={25}
                            color="white"
                            className="footer-icon"
                        />
                    </a>
                </div>
                <div>
                    <Link to="/politica-privacidad">
                        <p>Política de privacidad</p>
                    </Link>
                </div>
                <div>
                    <Link to="/politica-cookies">
                        <p>Política de cookies</p>
                    </Link>
                </div>
                <div>
                    <Link to="/aviso-legal">
                        <p>Aviso legal</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
