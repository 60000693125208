import axios from "axios";
import React, { useReducer, useState } from "react";
//import ReCAPTCHA from "react-google-recaptcha";
import { FaLinkedin } from "react-icons/fa";
import { FcTwoSmartphones } from "react-icons/fc";
import { Link } from "react-router-dom";
import "./contacto.css";

const Contacto = () => {
    //const recaptchaRef = React.createRef();

    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );

    const formReducer = (state, event) => {
        if (event.reset) {
            return {
                name: "",
                email: "",
                asunto: "",
                descripcion: "",
                lopd: false
            };
        }
        return {
            ...state,
            [event.name]: event.value
        };
    };

    const [formData, setFormData] = useReducer(formReducer, {
        name: "",
        email: "",
        asunto: "",
        descripcion: "",
        lopd: false
    });
    const [submitting, setSubmitting] = useState(false);
    const [emailError, setEmailError] = useState(true);
    //const [captchaError, setCaptchaError] = useState(true);

    const getToken = async () => {
        const server_url = process.env.REACT_APP_URL;

        const axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                Authorization:
                    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlhXVzZWcERmeGRUTktyVlBQeWM0MiJ9.eyJpc3MiOiJodHRwczovL2Nhcmxvc3BhcXVlY29tLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJ2VVV4dVc2RUhrU2luSnNiaFpZeWMyOEt2cXhsTEx3Y0BjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9jYXJsb3NwYXF1ZWNvbS5ldS5hdXRoMC5jb20vYXBpL3YyLyIsImlhdCI6MTY0NjMxOTc0NiwiZXhwIjoxNjQ2NDA2MTQ2LCJhenAiOiJ2VVV4dVc2RUhrU2luSnNiaFpZeWMyOEt2cXhsTEx3YyIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.Qk_M76IwmH2IsvB8QCt8kpVSm7PfZx6KD94U3NBOR_BZMtqRebA5VO2uAPR4oFv6Iw8zFIpKTUJ_9nsND4aWZGt8EtglSinhGhvf_G9Htyj5y5p6lYidt7gpPM1HjF-Roi_vZaht2fZdZ7NUSoPgKK0rjUo8w-NBGlIdnaQ7BH6s8j_wdHI2ScnDzjRAEEKIRcmRVyc3mvKMWT_8sb4QceVn_fFKzoOdJe-fyC-mF487b94ZC47u6ahNfwoS5IvjwLZIwnP4TWR3mYpTKj_EVzZpmWKsTLXhGuzYpbZXRFhVuey9B1f0iBigOEv996iTCxAQ331-VPxVoWVwZlpbLg"
            }
        };

        const data = await axios.get(`${server_url}/api/auth`, axiosConfig);
        return data;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);

        const server_url = process.env.REACT_APP_URL;
        let { data } = await getToken();
        let authToken = data.access_token;

        const axiosBearerConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${authToken}`
            }
        };

        axios
            .post(`${server_url}/api/create`, formData, axiosBearerConfig)
            .then((res) => {
                let textresponse = document.getElementById("respuesta");
                if (res.status === 201) {
                    textresponse.style.display = "block";
                    textresponse.innerText =
                        "Tu consulta ha sido enviada, pronto te contactaré.";
                } else {
                    textresponse.style.display = "block";
                    textresponse.innerText =
                        "Disculpa, ha ocurrido un error. Me puedes contactar vía carlos@carlospaque.com";
                }
            });
    };

    const handleChange = (event) => {
        const isCheckbox = event.target.type === "checkbox";
        if (event.target.name === "email") {
            if (validEmailRegex.test(event.target.value)) {
                setEmailError(false);
            } else {
                setEmailError(true);
            }
        }

        setFormData({
            name: event.target.name,
            value: isCheckbox ? event.target.checked : event.target.value
        });
    };

    /*const handleCaptcha = () => {
        setCaptchaError(false);

        const hideCaptcha = () => {
            const captcha = document.getElementById("captcha");
            captcha.style.opacity = "0.2";
        };

        setTimeout(hideCaptcha, 2000);
    };*/

    return (
        <>
            <div className="contact-wrapper" id="contacto">
                <div className="contact-left">
                    <h2>¿Cómo te puedo ayudar?</h2>
                    <p className="contacto-info">
                        Por favor, selecciona la forma de contacto más adecuada
                        para tu necesidad. Si no encuentras lo que buscas,
                        rellena nuestro formulario de contacto.
                    </p>
                    <div className="contact-paths">
                        <div className="contact-path">
                            <h3>⌚ Live Chat (Próximamente...)</h3>
                            <p>Habla conmigo de L-V de 9am a 6pm (España)</p>
                        </div>
                        <Link to="/area-clientes">
                            <div className="contact-path">
                                <h3>📑 Ticket de soporte</h3>
                                <p>¿Tienes algún problema con tu proyecto?</p>
                            </div>
                        </Link>
                        <a href="tel:+34613029137">
                            <div className="contact-path">
                                <h3>
                                    <FcTwoSmartphones /> Llámame al +34 613 029
                                    137
                                </h3>
                                <p>Te atenderé de L-V de 9am a 6pm (España)</p>
                            </div>
                        </a>
                        <a
                            href="https://www.linkedin.com/in/carlospaque/"
                            rel="noreferrer"
                            target="_blank"
                            className="linkedin"
                        >
                            <div className="contact-path">
                                <h3>
                                    <FaLinkedin /> LinkedIn
                                </h3>
                                <p>
                                    Pongámonos al día con nuestros intereses
                                    profesionales
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="contact-right">
                    <form onSubmit={handleSubmit}>
                        <fieldset disabled={submitting}>
                            <label>
                                <p>Empresa: </p>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={handleChange}
                                    value={formData.name || ""}
                                    required
                                    placeholder="Escribe el nombre de tu empresa"
                                    className="form-control"
                                />
                            </label>
                            <label>
                                <p>Correo electrónico: </p>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={formData.email || ""}
                                    required
                                    placeholder="Escribe tu correo electrónico"
                                />
                                {emailError && formData.email.length > 3 && (
                                    <p className="error">Correo inválido</p>
                                )}
                            </label>
                            <label>
                                <p>¿Dónde nos has conocido?</p>
                                <select
                                    name="asunto"
                                    id="asunto"
                                    onChange={handleChange}
                                    value={formData.asunto || ""}
                                    required
                                >
                                    <option value="" hidden>
                                        -Por favor, escoge una opción-
                                    </option>
                                    <option value="redes-sociales">
                                        Redes sociales
                                    </option>
                                    <option value="google">
                                        Búsqueda en Google
                                    </option>
                                    <option value="referido">
                                        Recomendación
                                    </option>
                                    <option value="prensa">Prensa</option>
                                    <option value="cliente">Soy cliente</option>
                                </select>
                            </label>
                            <label>
                                <p>Descripción: </p>
                                <textarea
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    onChange={handleChange}
                                    value={formData.descripcion || ""}
                                    required
                                    placeholder="Escribe tu consulta aquí"
                                />
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="lopd"
                                    onChange={handleChange}
                                    value={formData.lopd || false}
                                    required
                                />
                                <span>
                                    Consentimiento expreso RGPD para el
                                    tratamiento de datos de carácter personal.{" "}
                                    <Link to="aviso-legal">
                                        Consulta nuestro Aviso Legal
                                    </Link>
                                </span>
                            </label>

                            <button
                                id="sendForm"
                                type="submit"
                                disabled={emailError}
                            >
                                Envía
                            </button>
                            {submitting && <p id="respuesta"></p>}
                        </fieldset>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Contacto;
