import { lazy, Suspense } from "react";
import CookieConsent from "react-cookie-consent";
import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components";

//Carga de todos los componentes en modo lazyloading, hasta que no se necesiten no se cargarán
const AvisoLegal = lazy(() => import("./pages/aviso-legal/aviso-legal.jsx"));
const Home = lazy(() => import("./pages/home/Home.jsx"));
const PoliticaCookies = lazy(() =>
    import("./pages/politica-cookies/politica-cookies.jsx")
);
const PoliticaPrivacidad = lazy(() =>
    import("./pages/politica-privacidad/politica-privacidad.jsx")
);
const Login = lazy(() => import("./pages/login/Login.jsx"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard.jsx"));
const Footer = lazy(() => import("./components/footer/Footer.jsx"));
const Documentos = lazy(() =>
    import("./pages/dashboard/documentos/Documentos.jsx")
);
const Proyectos = lazy(() =>
    import("./pages/dashboard/proyectos/Proyectos.jsx")
);
const Presupuesto = lazy(() =>
    import("./pages/dashboard/presupuesto/Presupuesto.jsx")
);
const Ticket = lazy(() =>
    import("./pages/dashboard/ticket-soporte/Ticket.jsx")
);
const Master = lazy(() => import("./pages/master/Master.jsx"));

// Definición de rutas para nuestra aplicación y páginas y componentes a cargar
function App() {
    return (
        <div>
            <Navbar />
            <Suspense fallback={<div className="loader"></div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                        path="/politica-cookies/"
                        element={<PoliticaCookies />}
                    />
                    <Route
                        path="/politica-privacidad/"
                        element={<PoliticaPrivacidad />}
                    />
                    <Route path="/aviso-legal/" element={<AvisoLegal />} />
                    <Route path="*" element={<Home />} />
                    <Route path="/area-clientes/" element={<Login />} />
                    <Route path="/master/" element={<Master />} />
                    <Route path="/dashboard/" element={<Dashboard />} />
                    <Route
                        path="/dashboard/documentos"
                        element={<Documentos />}
                    />
                    <Route
                        path="/dashboard/proyectos"
                        element={<Proyectos />}
                    />
                    <Route
                        path="/dashboard/presupuesto"
                        element={<Presupuesto />}
                    />
                    <Route
                        path="/dashboard/ticket-soporte"
                        element={<Ticket />}
                    />
                </Routes>
                <Footer />
            </Suspense>
            <CookieConsent
                location="bottom"
                buttonText="De acuerdo"
                cookieName="AcceptBasicCookies"
                expires={3600}
            >
                <p className="cookiepolicy">
                    Con su consentimiento, usamos cookies imprescrindibles para
                    el funcionamiento de este sitio web para almacenar, acceder
                    y procesar datos personales como su visita en este sitio
                    web. Puede retirar su consentimiento u oponerse al
                    procesamiento tratamiento de datos basado en intereses
                    legítimos en cualquier momento configurando su navegador web
                    para rechazarlas o en nuestra{" "}
                    <Link to="politica-cookies" className="cookiepolicy">
                        Política de Cookies.
                    </Link>{" "}
                </p>
            </CookieConsent>
        </div>
    );
}

export default App;
