import React from "react";
import { HashLink } from "react-router-hash-link";
import "./servicios.css";

const Servicios = ({ title }) => {
    /*var services = [
        {
            key: "Integrador de sistemas",
            title: "Integrador de sistemas",
            imgpath: DataAccess,
            link: "/post/integrador-sistemas",
            info1: ""
        },
        {
            key: "Ingeniero electrónico",
            title: "Ingeniero electrónico",
            imgpath: HMI,
            link: "/post/ingeniero-electronico",
            info1: ""
        },
        {
            key: "Desarrollador WebApps",
            title: "Desarrollador WebApps",
            imgpath: MobileConnected,
            link: "/post/desarrollador-webapps",
            info1: ""
        },
        {
            key: "Consultor digital",
            title: "Consultor digital",
            imgpath: Consultor,
            link: "/post/consultor-digital",
            info1: ""
        }
    ];*/

    /*const listservices = services.map((service) => {
        return (
            <Link to={service.link} key={service.title}>
                <div className="servicios-column">
                    <div className="servicios-card">
                        <div className="servicios-card-top">
                            <div className="img-wrapper">
                                <img
                                    src={service.imgpath}
                                    alt={service.title}
                                    loading="lazy"
                                />
                            </div>
                            <p className="servicios-title">{service.title}</p>
                        </div>
                        <div className="servicios-card-bottom">
                            <p>{service.info1}</p>
                        </div>
                    </div>
                </div>
            </Link>
        );
    });*/

    return (
        <>
            <div className="service-wrapper">
                <div className="container-left">
                    <div className="servicios section__padding">
                        <div className="servicios-container-text">
                            <h1 id="servicios">
                                Integrador de sistemas & WebApp Developer
                            </h1>
                        </div>
                        <h2 className="servicios-info">
                            👋¡Hola, soy Carlos! Ayudo a tu empresa a aportar
                            valor mediante{" "}
                            <strong>
                                soluciones de integración personalizadas
                            </strong>{" "}
                            y llaves en mano a tu cliente final.
                        </h2>
                        <p className="servicios-info">
                            Colaboro con profesionales y empresas a{" "}
                            <strong>
                                reconvertirse en la industria 4.0 y escalar sus
                                proyectos y negocios
                            </strong>
                            .
                        </p>
                        <p className="servicios-info">
                            Soy ingeniero electrónico industrial con más de 5
                            años de experiencia y{" "}
                            <strong>WebApp Developer</strong> para empresas.
                            Desarrollo proyectos web personalizados, soluciones
                            IoT relacionadas con la{" "}
                            <strong>Industria 4.0</strong> con análisis y
                            visualización de datos de forma remota, integración
                            de tu Big Data en tu sistema de gestión ERP y mucho
                            más.{" "}
                            <HashLink to="#contacto">¿Conectamos?</HashLink>
                        </p>
                        <div className="servicios-container">
                            <div className="servicios-container-card"></div>
                        </div>
                    </div>
                </div>
                <div className="container-right" loading="lazy">
                    <div className="background-img"></div>
                </div>
            </div>

        </>
    );
};

//{listservices}

export default Servicios;
