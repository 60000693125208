import React, { useState } from "react";
import { BsLinkedin } from "react-icons/bs";
import { RiCloseLine, RiGithubFill, RiMenu3Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/logo.webp";
import "./navbar.css";

const Menu = () => (
    <>
        <div className="navbar-links_logo social">
            <a
                href="https://www.linkedin.com/in/carlospaque/"
                rel="noreferrer"
                target="_blank"
                className="linkedin"
            >
                <BsLinkedin size={25} color="black" />
            </a>
        </div>
        <div className="navbar-links_logo social">
            <a
                href="https://github.com/bartolomeow"
                rel="noreferrer"
                target="_blank"
                className="github"
            >
                <RiGithubFill size={25} color="black" />
            </a>
        </div>
        <HashLink smooth to="home#servicios">
            <p>Quién soy</p>
        </HashLink>
        <HashLink smooth to="home#proyectos">
            <p>Proyectos</p>
        </HashLink>
        <Link to="/area-clientes/">
            <p>Área clientes</p>
        </Link>
    </>
);

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <div className="navbar">
            <div className="navbar-links">
                <div className="navbar-links_logo">
                    <Link to="/">
                        <img width="50" height="50" src={logo} alt="logo" />
                        <h1>
                            Carlos{" "}
                            <span className="logo-bold">
                                Paquē{" "}
                                <span className="logo-grey">
                                    <span className="vertbar">|</span> I+D
                                </span>
                            </span>{" "}
                            <span className="logo-grey">Systems</span>
                        </h1>
                    </Link>
                </div>
                <div className="navbar-links_container">
                    <Menu />
                </div>
            </div>
            <div className="navbar-sign">
                <div>
                    <HashLink to="#contacto">
                        <button type="button" className="primary-btn">
                            Contacto
                        </button>
                    </HashLink>
                </div>
            </div>
            <div className="navbar-menu">
                {toggleMenu ? (
                    <RiCloseLine
                        color="#000"
                        size={27}
                        onClick={() => setToggleMenu(false)}
                    />
                ) : (
                    <RiMenu3Line
                        color="#000"
                        size={27}
                        onClick={() => setToggleMenu(true)}
                    />
                )}
                {toggleMenu && (
                    <div className="navbar-menu_container scale-up-center">
                        <div className="navbar-menu_container-links">
                            <Menu />
                        </div>
                        <div className="navbar-menu_container-links-sign">
                            <div>
                                <HashLink to="home#contacto">
                                    <button
                                        type="button"
                                        className="primary-btn"
                                    >
                                        Contacto
                                    </button>
                                </HashLink>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
